exports.numbers = {
  mtn: [
    "0803",
    "0806",
    "0810",
    "0813",
    "0814",
    "0816",
    "0702",
    "0703",
    "0704",
    "0706",
    "0903",
    "0906",
    "0913",
    "0916",
  ],
  glo: ["0805", "0807", "0811", "0815", "0705", "0905", "0915"],
  airtel: [
    "0802",
    "0808",
    "0812",
    "0701",
    "0708",
    "0901",
    "0902",
    "0904",
    "0907",
    "0912",
    "0911",
  ],
  "9mobile": ["0809", "0817", "0818", "0908", "0909"],
};
