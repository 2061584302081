import React, { useEffect, useState } from "react";

import "../../assets/scss/SF.scss";
import { Link, useParams } from "react-router-dom";
import { BsWhatsapp, BsInstagram, BsFacebook } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import { RiTwitterXFill } from "react-icons/ri";
import { FiShare } from "react-icons/fi";
import { BsChevronRight } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import toast from "react-hot-toast";
import logo from "../../assets/images/logos/wisperN.png";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Alert,
  Label,
  Form,
  UncontrolledAlert,
  ModalHeader,
} from "reactstrap";
import { validateProperty } from "../../utils";
import {
  allocateAirtimeSF,
  allocateData,
  allocateSFData,
  getAllPlansUser,
  getCustomerName,
  getUsernameStoreFront,
} from "../../services/dataService";
import Loader from "../../layouts/loader/Loader";
import timer from "../../assets/images/users/packages.svg";
import empty from "../../assets/images/users/cart.png";
import { ShareSocial } from "react-share-social";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { numbers } from "../../networkCheckout";
import CopyToClipboard from "react-copy-to-clipboard";
import cancel from "../../assets/images/logos/cancel.png";
import checked from "../../assets/images/logos/checked.png";
import { useUser } from "../../context/userContext";
import { Helmet } from "react-helmet";
import StoreFront from "./StoreFront";
import { getSFMaintenance } from "../../services/Admin.Services/controlService";
const { REACT_APP_FLUTTERWAVE_PUBLIC_KEY } = process.env;

const initialState = {
  network: "airtel",
  volume: "",
  phone_number: "",
};
const SFCustomer = () => {
  const [plan, setPlan] = useState(initialState);
  const { user } = useUser();
  const [maintenance, setMaintenance] = useState({});

  const { storeUserName } = useParams();

  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    size: "",
    network: "",
    phone: "",
    price: "",
  });
  const [success1, setSuccess1] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirm1, setConfirm1] = useState(false);
  const [prices, setPrices] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failed1, setFailed1] = useState(false);
  const [copyState, setCopyState] = useState(false);
  const [customerName, setCustomerName] = useState({
    name: "",
    state: false,
  });

  const [customerEmail, setCustomerEmail] = useState({
    email: "",
    state: false,
  });

  const [storeFront, setStoreFront] = useState({});
  const [activePlan, setActivePlan] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [account, setAccount] = useState({
    phone: "",
    name: "",
    storeBusiness: "",
    email: "",
    price: "", // or null if not applicable
    volume: "", // or null if not applicable
    airtime_volume: "",
    // status: "", // or null if not applicable
    network: "", // or null if not applicable
    transaction_ref: "", // Unique reference identifier
  });
  const [errors, setErrors] = useState({});
  const [serverResponse, setServerResponse] = useState({
    status: true,
    message: "",
  });
  // Function to change the favicon
  function changeFavicon(newFaviconUrl) {
    const linkElement = document.querySelector("link[rel='icon']");

    if (linkElement) {
      // Update the href attribute of the link element to the new favicon URL
      linkElement.href = newFaviconUrl;
    } else {
      // If the link element doesn't exist, create it and add it to the head
      const newLinkElement = document.createElement("link");
      newLinkElement.rel = "icon";
      newLinkElement.href = newFaviconUrl;
      document.head.appendChild(newLinkElement);
    }
  }

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchStoreFront = async () => {
      try {
        setLoading(true);
        const resp = await getUsernameStoreFront(storeUserName);
        setStoreFront(resp.data);
        setLoading(false);

        // Set the page title using resp.data.storeName
        // if (resp.data.storeName) {
        //   document.title = resp.data.storeName;
        // }
        // if (resp.data.storeImg) {
        //   await changeFavicon(resp.data.storeImg);
        // }
      } catch (error) {
        setErrors("error");
        setLoading(false);
      }
    };

    fetchStoreFront();
  }, []);

  useEffect(() => {
    const fetchAllPlansUser = async () => {
      await getAllPlansUser(storeFront.business_id).then((res) => {
        setPrices(res?.data);
      });
    };

    fetchAllPlansUser();
  }, [storeFront.business_id]);

  useEffect(() => {
    const fetchCustomerName = async () => {
      await getCustomerName(account.phone).then((res) => {
        // setAccount({ ...account, name: res?.data });
        if (res?.data) {
          setCustomerName({
            name: res?.data?.name,
            state: true,
          });

          setCustomerEmail({
            email: res?.data?.email,
            state: true,
          });
        } else {
          setCustomerName({
            name: "",
            state: false,
          });

          setCustomerEmail({
            email: "",
            state: false,
          });
        }
      });
    };

    fetchCustomerName();
  }, [account.phone]);

  const style = {
    background: "primary",
    borderRadius: 3,
    border: 0,
    width: "100%",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "black",
    padding: "1rem",
    cursor: "pointer",
  };

  const paymentConfig = {
    public_key: REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: "trx-" + Math.floor(Math.random() * 10000000000000000),
    amount: activePlan.selling_price,
    currency: "NGN",
    payment_options: "card, mobilemoney, banktransfer, ussd",
    customer: {
      name: customerName.name,
      phone_number: account.phone,
      email: customerEmail.email,
    },
    customizations: {
      title: "Buy Data",
      description: `Buy Data from ${storeFront.storeUserName}'store `,
    },
  };

  const paymentConfig1 = {
    public_key: REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: "trx-" + Math.floor(Math.random() * 10000000000000000),
    amount: account.airtime_volume,
    currency: "NGN",
    payment_options: "card, mobilemoney, banktransfer, ussd",
    customer: {
      name: customerName.name,
      phone_number: account.phone,
      email: customerEmail.email,
    },
    customizations: {
      title: "Purchase Airtime",
      description: `Purchase Airtime from ${storeFront.storeUserName}'store `,
    },
  };
  const initiatePayment = useFlutterwave(paymentConfig);
  const initiatePayment1 = useFlutterwave(paymentConfig1);

  const makePayment = () => {
    initiatePayment({ callback: onSuccess, onClose });
  };

  const makePayment1 = () => {
    initiatePayment1({ callback: onSuccess1, onClose1 });
  };

  const handleSubmit = async (response) => {
    setLoading(true);

    await allocateSFData({
      network: account.network,
      plan_id: activePlan.plan_id,
      phone_number: account.phone,
      business_id: storeFront.business_id,
      price: activePlan.selling_price,
      volume: activePlan.volume,
      trx_ref: String(response),
      custName: customerName.name,
      custEmail: customerEmail.email,
    });

    setSuccessMessage({
      network: account.network,
      size: activePlan?.size,
      phone: account.phone,
      price: activePlan?.selling_price,
    });
    setLoading(false);

    setErrors({});
  };

  const handleSubmit1 = async (response) => {
    setLoading(true);

    const res = await allocateAirtimeSF({
      network: account.network,
      phone_number: account.phone,
      business_id: storeFront.business_id, // only when using store front this is require else use the x-api-key for normal platform allocation
      volume: account.airtime_volume,
      price: account.airtime_volume,
      email: customerEmail.email, // optional only for store front
      name: customerName.name,
    });
    setLoading(false);

    setErrors({});
  };

  const onSuccess = async (response) => {
    closePaymentModal();
    try {
      await handleSubmit(response.transaction_id);
      setConfirm(false);
      setSuccess(true);

      setAccount({
        phone: "",
        name: "",
        storeBusiness: "",
        email: "",
        airtime_volume: "",
        price: "", // or null if not applicable
        volume: "", // or null if not applicable
        // status: "", // or null if not applicable
        network: "", // or null if not applicable
        transaction_ref: "", // Unique reference identifier
      });
      setActivePlan("");
    } catch (error) {
      setErrors(true);
      setConfirm(false);
      setFailed(true);
    }
  };

  const onSuccess1 = async (response) => {
    closePaymentModal();
    try {
      await handleSubmit1(response.transaction_id);
      setConfirm1(false);

      setSuccess1(true);
      setAccount({
        phone: "",
        name: "",
        storeBusiness: "",
        email: "",
        price: "", // or null if not applicable
        volume: "", // or null if not applicable
        // status: "", // or null if not applicable
        network: "", // or null if not applicable
        transaction_ref: "", // Unique reference identifier
      });
    } catch (error) {
      setErrors(true);
      setConfirm1(false);
      setFailed1(true);
    }
  };

  const onClose = () => {
    setConfirm(false);
  };

  const onClose1 = () => {
    setConfirm1(false);
  };

  const handleChange = ({ currentTarget: input }) => {
    const validationErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) validationErrors[input.name] = errorMessage;
    else delete validationErrors[input.name];

    const { name, value } = input;

    let phoneNumberPrefix;
    let network;
    // Determine the network based on the phone number's prefix
    if (name == "phone") {
      phoneNumberPrefix = value.substring(0, 4);
      if (numbers.mtn.includes(phoneNumberPrefix)) {
        network = "mtn";
      } else if (numbers.glo.includes(phoneNumberPrefix)) {
        network = "glo";
      } else if (numbers["9mobile"].includes(phoneNumberPrefix)) {
        network = "9mobile";
      } else if (numbers.airtel.includes(phoneNumberPrefix)) {
        network = "airtel";
      }
    }

    setAccount({ ...account, [name]: value, network: network });
    setErrors(validationErrors);
  };

  const handlePlanChange = ({ currentTarget: input }) => {
    const { name, value } = input;
    if (value == "select") {
      setActivePlan(value);
    } else {
      const selectedPlan = JSON.parse(value);
      setActivePlan(selectedPlan);
    }
  };

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Wisper Store",
          text: `Check out this Wisper Store`,
          url: storeFront.storeURL, // Replace with your desired URL
        });
      } catch (error) {
        console.error("Share failed:", error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert("Native sharing is not supported on this browser.");
    }
  };

  function getFirstLetters(inputString) {
    // Split the input string into words based on spaces
    const words = inputString.split(" ");

    // Check if there is at least one word
    if (words.length > 0) {
      // Get the first letter of the first word
      const firstLetter = words[0][0];

      // Check if there is a second word
      if (words.length > 1) {
        // Get the first letter of the second word
        const secondLetter = words[1][0];

        // Return both first letters
        return `${firstLetter}${secondLetter}`;
      } else {
        // Only one word, return its first letter
        return firstLetter;
      }
    }

    // No words found, return an empty string or handle as needed
    return "";
  }

  useEffect(() => {
    const getSFMaintenanceFunc = async () => {
      // setLoading(true);
      const resp = await getSFMaintenance();
      setMaintenance(resp?.data?.maintenance);

      // setLoading(false);
    };

    getSFMaintenanceFunc();
  }, []);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          {errors == "error" ? (
            <div className="sf__customer__container">
              <div className="sf__customer__content">
                <div className="sf__customer__maintenance">
                  <img src={empty} />
                  <h3>This Store Front Account does not Exist</h3>
                </div>

                <div className="sf__customer__footer">
                  <img src={logo} />
                  <p>Powered by Wisper</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {storeFront.storeMaintenance ? (
                <div className="sf__customer__container">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{storeFront?.storeName}</title>
                    <link
                      rel="canonical"
                      href="https://postimgs.org/img/logo.png"
                    />{" "}
                  </Helmet>
                  <div className="sf__customer__content">
                    <div className="sf__customer__maintenance">
                      <img src={timer} />
                      <h3>This Store Front Account is on Maintenance Mode</h3>
                      <h5>Please Check in Later</h5>
                    </div>

                    <div className="sf__customer__footer">
                      <img src={logo} />
                      <p>Powered by Wisper</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sf__customer__container">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{storeFront?.storeName}</title>
                    <link
                      rel="canonical"
                      href="https://postimgs.org/img/logo.png"
                    />
                  </Helmet>
                  <div className="sf__customer__content">
                    <div className="sf__customer__top">
                      <div className="sf__share">
                        {" "}
                        <Button
                          style={{
                            border: "none",
                            background: `${
                              storeFront.storeColor
                                ? storeFront.storeColor
                                : "black"
                            }`,
                          }}
                          onClick={() => {
                            setIsOpen(true);
                            // handleShareClick();
                          }}
                        >
                          Share Store
                        </Button>
                      </div>
                      <div className="sf__customer__logo">
                        <span
                          style={{
                            border: `3px solid ${
                              storeFront.storeColor
                                ? storeFront.storeColor
                                : "black"
                            }`,
                          }}
                        >
                          {storeFront?.storeImg == "" ||
                          !storeFront?.storeImg ? (
                            <h1>
                              {getFirstLetters(storeFront?.storeName ?? "")}
                            </h1>
                          ) : (
                            <img src={storeFront?.storeImg} />
                          )}
                        </span>

                        <div className="sf__customer__head">
                          <h3>{storeFront?.storeName}</h3>
                          <p>{storeFront?.storeDesc}</p>
                        </div>
                        <div className="sf__customer__socials">
                          {storeFront?.socialLinks?.whatsapp !== "" ||
                          storeFront?.socialLinks?.whatsapp ? (
                            <a
                              target="_blank"
                              href={`https://wa.me/${storeFront?.socialLinks?.whatsapp?.replace(
                                /^0+/,
                                "234"
                              )}?text=`}
                            >
                              <BsWhatsapp
                                cursor={"pointer"}
                                color={
                                  storeFront.storeColor
                                    ? storeFront.storeColor
                                    : "black"
                                }
                              />
                            </a>
                          ) : (
                            ""
                          )}

                          {storeFront?.socialLinks?.instagram !== "" ||
                          storeFront?.socialLinks?.instagram ? (
                            <a
                              target="_blank"
                              href={storeFront?.socialLinks?.instagram}
                            >
                              <BsInstagram
                                cursor={"pointer"}
                                color={
                                  storeFront.storeColor
                                    ? storeFront.storeColor
                                    : "black"
                                }
                              />
                            </a>
                          ) : (
                            ""
                          )}

                          {storeFront?.socialLinks?.twitter !== "" ||
                          storeFront?.socialLinks?.twitter ? (
                            <a
                              target="_blank"
                              href={storeFront?.socialLinks?.twitter}
                            >
                              <RiTwitterXFill
                                cursor={"pointer"}
                                color={
                                  storeFront.storeColor
                                    ? storeFront.storeColor
                                    : "black"
                                }
                              />
                            </a>
                          ) : (
                            ""
                          )}

                          {storeFront?.socialLinks?.facebook !== "" ||
                          storeFront?.socialLinks?.facebook ? (
                            <a
                              target="_blank"
                              href={storeFront?.socialLinks?.facebook}
                            >
                              <BsFacebook
                                cursor={"pointer"}
                                color={
                                  storeFront.storeColor
                                    ? storeFront.storeColor
                                    : "black"
                                }
                              />
                            </a>
                          ) : (
                            ""
                          )}

                          {storeFront?.phoneNumber !== "" ||
                          storeFront?.phoneNumber ? (
                            <a
                              target="_blank"
                              href={`tel:${storeFront?.phoneNumber}`}
                            >
                              <IoMdCall
                                cursor={"pointer"}
                                color={
                                  storeFront.storeColor
                                    ? storeFront.storeColor
                                    : "black"
                                }
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="sf__customer__buttons">
                        <h4>Services</h4>

                        <button
                          style={{
                            background: `${
                              storeFront.storeColor
                                ? storeFront.storeColor
                                : "black"
                            }`,
                          }}
                          onClick={() => {
                            if (
                              maintenance["purchase"] &&
                              maintenance["purchase"] == true
                            ) {
                              toast.error(
                                "The Store front Data Purchase feature is on maintenance, check in later!"
                              );
                            } else {
                              setConfirm(true);
                            }
                          }}
                        >
                          Buy Data
                        </button>

                        {!storeFront?.userType == "glo_dealer" ||
                        !storeFront?.userType == "glo_agent" ? (
                          <button
                            style={{
                              background: `${
                                storeFront.storeColor
                                  ? storeFront.storeColor
                                  : "black"
                              }`,
                            }}
                            onClick={() => {
                              if (
                                maintenance["purchase"] &&
                                maintenance["purchase"] == true
                              ) {
                                toast.error(
                                  "The Store front Airtime Purchase feature is on maintenance, check in later!"
                                );
                              } else {
                                setConfirm1(true);
                              }
                            }}
                          >
                            Buy Airtime
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="sf__customer__footer">
                      <a
                        target="_blank"
                        href={`${window.location.protocol}//${window.location.host} `}
                      >
                        <img src={logo} />
                      </a>

                      <p>Powered by Wisper</p>
                    </div>
                  </div>

                  <Modal
                    centered
                    isOpen={confirm}
                    toggle={() => setConfirm(!confirm)}
                  >
                    <ModalBody>
                      <div className="add__sub__dealer__con">
                        <div className="add__sub__dealer__head">
                          <h4>Buy Data</h4>
                          {/* <h5>Empower Sub-Dealers to Grow Together</h5> */}
                        </div>

                        <Form>
                          <FormGroup className="mb-3">
                            <Label>
                              Phone number{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={account.phone}
                              invalid={errors.phone}
                              onChange={handleChange}
                              name="phone"
                              required
                              type="number"
                            />
                            <FormFeedback>{errors.phone}</FormFeedback>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label>Customer Name</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={(e) => {
                                setCustomerName({
                                  ...customerName,
                                  name: e.target.value,
                                });
                              }}
                              value={customerName.name}
                              disabled={customerName.state == true}
                              type="text"
                              required
                              name="name"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label>Email</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={(e) => {
                                setCustomerEmail({
                                  ...customerEmail,
                                  email: e.target.value,
                                });
                              }}
                              value={customerEmail.email}
                              disabled={customerEmail.state == true}
                              type="text"
                              required
                              name="email"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label>Network</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={handleChange}
                              value={account.network}
                              type="text"
                              required
                              name="network"
                              disabled
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label>Data Plans</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={handlePlanChange}
                              name="activePlan"
                              value={
                                activePlan == "select"
                                  ? activePlan
                                  : JSON.stringify(activePlan)
                              }
                              className="mb-3"
                              type="select"
                              disabled={account.network == ""}
                              required
                            >
                              {account.network == "" ? (
                                <option value="select">
                                  Select a valid number
                                </option>
                              ) : (
                                <>
                                  <option value="select">Select a plan</option>
                                  {prices
                                    .filter(
                                      (plan) =>
                                        plan.network === account.network &&
                                        plan.selling_price
                                    )
                                    .map((plan) => (
                                      <option
                                        key={plan.id}
                                        value={JSON.stringify(plan)}
                                      >
                                        {plan.size} - {plan.validity}
                                      </option>
                                    ))}
                                </>
                              )}
                            </Input>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label>Price</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              disabled
                              value={`₦${
                                activePlan.selling_price
                                  ? activePlan.selling_price
                                  : ""
                              }`}
                              type="text"
                              name="price"
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </ModalBody>
                    <ModalFooter className="confirm-footer">
                      <Button
                        color="primary"
                        onClick={() => {
                          if (
                            account.phone == "" ||
                            customerName.name == "" ||
                            customerEmail.email == "" ||
                            account.network == "" ||
                            activePlan == "select" ||
                            activePlan == "" ||
                            !activePlan ||
                            activePlan.selling_price == ""
                          ) {
                            toast.error("incomplete information");
                          } else {
                            makePayment();
                          }
                        }}
                        // disabled={formIsValid(errors) || loading}
                        size="lg"
                        type="submit"
                        className="submit-btn"
                      >
                        Pay Now
                      </Button>{" "}
                      <Button onClick={() => setConfirm(false)}>
                        No, Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    centered
                    isOpen={confirm1}
                    toggle={() => setConfirm(!confirm1)}
                  >
                    <ModalBody>
                      <div className="add__sub__dealer__con">
                        <div className="add__sub__dealer__head">
                          <h4>Buy Airtime</h4>
                        </div>

                        <Form>
                          <FormGroup className="mb-3">
                            <Label>
                              Phone number{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              value={account.phone}
                              invalid={errors.phone}
                              onChange={handleChange}
                              name="phone"
                              required
                              type="number"
                            />
                            <FormFeedback>{errors.phone}</FormFeedback>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label>Customer Name</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={(e) => {
                                setCustomerName({
                                  ...customerName,
                                  name: e.target.value,
                                });
                              }}
                              value={customerName.name}
                              disabled={customerName.state == true}
                              type="text"
                              required
                              name="name"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label>Email</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={(e) => {
                                setCustomerEmail({
                                  ...customerEmail,
                                  email: e.target.value,
                                });
                              }}
                              value={customerEmail.email}
                              disabled={customerEmail.state == true}
                              type="text"
                              required
                              name="email"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label>Network</Label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              onChange={handleChange}
                              value={account.network}
                              type="text"
                              required
                              name="network"
                              disabled
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label for="airtime_volume">Amount</Label>
                            <Input
                              value={account.airtime_volume}
                              invalid={errors.airtime_volume}
                              id="airtime_volume"
                              name="airtime_volume"
                              onChange={handleChange}
                              type="number"
                            />{" "}
                            <FormFeedback>{errors.airtime_volume}</FormFeedback>
                          </FormGroup>
                        </Form>
                      </div>
                    </ModalBody>
                    <ModalFooter className="confirm-footer">
                      <Button
                        color="primary"
                        onClick={() => {
                          if (
                            account.phone == "" ||
                            customerName.name == "" ||
                            customerEmail.email == "" ||
                            account.network == "" ||
                            account.airtime_volume == ""
                          ) {
                            toast.error("incomplete information");
                          } else {
                            makePayment1();
                          }
                        }}
                        // disabled={formIsValid(errors) || loading}
                        size="lg"
                        type="submit"
                        className="submit-btn"
                      >
                        Pay Now
                      </Button>{" "}
                      <Button onClick={() => setConfirm1(false)}>
                        No, Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              )}
            </>
          )}
        </>
      )}
      <Modal centered isOpen={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader toggle={() => setIsOpen(false)}>Share Store</ModalHeader>
        <ModalBody>
          <div className="sf__share__con">
            <div onClick={handleShareClick} style={style}>
              <div className="sf__share__options">
                <span>
                  <FiShare /> Share Options
                </span>
                <BsChevronRight />
              </div>
            </div>
            <div style={style}>
              <div className="sf__share__options">
                <span>
                  <img alt="logo" src={logo} /> {storeFront.storeURL}
                </span>

                {copyState ? (
                  <b
                  // onClick={() => {
                  //   navigator.clipboard.writeText(storeFront.storeURL ?? "");
                  //   setCopyState(!copyState);
                  // }}
                  >
                    Copied{" "}
                  </b>
                ) : (
                  <b
                    onClick={() => {
                      navigator.clipboard.writeText(storeFront.storeURL ?? "");
                      setCopyState(!copyState);
                    }}
                  >
                    Copy
                  </b>
                )}
              </div>
            </div>
          </div>
        </ModalBody>

        {/* <ModalFooter className="confirm-footer">
          <Button type="submit" color="primary" onClick={(e) => {}}>
            Yes, Proceed
          </Button>{" "}
          <Button onClick={() => setIsOpen(false)}>No, Cancel</Button>
        </ModalFooter> */}
      </Modal>

      <Modal
        centered
        isOpen={success}
        toggle={() => {
          setSuccess(!success);
          setAccount({});
          setActivePlan("");
          setCustomerEmail({});
          setCustomerName({});
        }}
      >
        <ModalBody>
          <div className="confirm text-center">
            <img src={checked} className="confirm-checked" alt="success" />
            <p>
              You successfully purchased {successMessage?.size} worth of{" "}
              {successMessage?.network} data to {successMessage?.phone} with ₦
              {successMessage?.price}{" "}
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="confirm-footer">
          <Button
            color="secondary"
            onClick={() => {
              setSuccess(false);
              setAccount({});
              setActivePlan("");
              setCustomerEmail({});
              setCustomerName({});
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        isOpen={success1}
        toggle={() => {
          setSuccess1(!success1);
          setAccount({});
          setActivePlan("");
          setCustomerEmail({});
          setCustomerName({});
        }}
      >
        <ModalBody>
          <div className="confirm text-center">
            <img src={checked} className="confirm-checked" alt="success" />
            <p>You successfully purchased Airtime</p>
          </div>
        </ModalBody>
        <ModalFooter className="confirm-footer">
          <Button
            color="secondary"
            onClick={() => {
              setSuccess1(false);
              setAccount({});
              setActivePlan("");
              setCustomerEmail({});
              setCustomerName({});
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Failure On Data sent*/}
      <Modal
        centered
        isOpen={failed}
        toggle={() => {
          setFailed(!failed);
          window.location.reload();
        }}
      >
        <ModalBody>
          <div className="confirm text-center">
            <img
              src={cancel}
              width={50}
              className="confirm-cancel"
              alt="confirm"
            />
            <p>Data purchase failed, wait for a refund</p>
          </div>
        </ModalBody>
        <ModalFooter className="confirm-footer">
          <Button
            color="secondary"
            onClick={() => {
              setFailed(false);
              window.location.reload();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        isOpen={failed1}
        toggle={() => {
          setFailed1(!failed1);
          window.location.reload();
        }}
      >
        <ModalBody>
          <div className="confirm text-center">
            <img
              src={cancel}
              width={50}
              className="confirm-cancel"
              alt="confirm"
            />
            <p>Airtime purchase failed, wait for a refund</p>
          </div>
        </ModalBody>
        <ModalFooter className="confirm-footer">
          <Button
            color="secondary"
            onClick={() => {
              setFailed1(false);
              window.location.reload();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SFCustomer;
